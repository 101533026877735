import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  // const siteTitle = data.site.siteMetadata.title

  // return (
  //   <Layout location={location} title={siteTitle}>
  //     <Seo title="404: Not Found" />
  //     <h1>404: Not Found</h1>
  //     <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  //   </Layout>
  // )
  if (typeof window !== "undefined") {
    window.location.replace("https://support.metro-fs.com")
  }

  return null
}

export default NotFoundPage

export const pageQuery = graphql`
  query site {
    site {
      siteMetadata {
        title
      }
    }
  }
`
